<template>
  <v-container fluid class="down-top-padding" v-cloak>
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
<!--        <stepper :exam="record"/>-->
        <base-tab-panel
          :module_name="page.name"
          :record="record"
          @change="save"
          @archive="archive"
          :loading="loading"
          :model="page.model">
          <template v-slot:main_fields>
            <v-row>
              <v-col>
                <v-text-field :class="'ma-0 pt-'+$store.getters.lineheight" :label="$store.getters.translate('name')" v-model="record.name">
                  <template v-if="usual_fields_required['name']" #label>{{ $store.getters.translate('name') }}<span class="red--text"><strong>*</strong></span></template>
                </v-text-field>
                <v-select
                    :class="'ma-0 pt-'+$store.getters.lineheight"
                    v-model="selected_location"
                    :label="$store.getters.translate('location')"
                    :items="$lodash.sortBy(locations, 'name')"
                    :return-object="true"
                    item-text="name"
                    item-value="id"
                    cache-items
                    :disabled="loading"
                    clearable
                    @change="getRooms">
                  <template v-if="usual_fields_required['location_id']" #label>{{ $store.getters.translate('location') }}<span class="red--text"><strong>*</strong></span></template>
                </v-select>
                <v-select
                    :class="'ma-0 pt-'+$store.getters.lineheight"
                    v-model="selected_room"
                    :label="$store.getters.translate('room')"
                    :items="$lodash.sortBy(rooms, 'name')"
                    :return-object="true"
                    item-text="name"
                    item-value="id"
                    clearable
                    :disabled="loading"
                    :loading="loading"
                    @change="getAssessors">
                  <template v-if="usual_fields_required['room_id']" #label>{{ $store.getters.translate('room') }}<span class="red--text"><strong>*</strong></span></template>
                </v-select>
                <v-text-field
                    :class="'ma-0 pt-'+$store.getters.lineheight"
                    :label="$store.getters.translate('examination_date')"
                    v-model="record.examination_date"
                    v-mask="'##-##-####'"
                    placeholder="dd-mm-yyyy"
                    clearable
                    @change="checkCapacity">
                  <template v-if="usual_fields_required['examination_date']" #label>{{ $store.getters.translate('examination_date') }}<span class="red--text"><strong>*</strong></span></template>
                </v-text-field>
                <template v-if="record.examination_date !== old_examination_date || record.room_id !== old_room_id">
                  <small class="mt-2 red--text">{{ exams_message }}</small><br/><br/>
                </template>
                <v-text-field :class="'ma-0 pt-'+$store.getters.lineheight" :label="$store.getters.translate('reference')" v-model="record.reference">
                  <template v-if="usual_fields_required['reference']" #label>{{ $store.getters.translate('reference') }}<span class="red--text"><strong>*</strong></span></template>
                </v-text-field>
<!--                <v-select
                    :label="$store.getters.translate('exam_language')"
                    v-model="record.exam_language"
                    :items="languages"
                    item-text="text"
                    item-value="value"
                    class="mb-5"
                    clearable/>-->
<!--            <v-switch :class="'ma-0 pt-'+$store.getters.lineheight" :label="$store.getters.translate('re_certification')" v-model="record.re_certification"/>-->
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th style="padding: 0;">{{ $store.getters.translate('candidate_name') }}</th>
                        <th style="width: 140px;text-align: center;padding: 0;">{{ $store.getters.translate('custom_order_number') }}</th>
                        <th style="width: 140px;text-align: center;padding: 0;">{{ $store.getters.translate('order_id') }}</th>
                        <th style="width: 60px;padding: 0;">{{ $store.getters.translate('is_billed') }}</th>
                        <th style="width: 140px;text-align: center;padding: 0;">{{ $store.getters.translate('invoice_id') }}</th>
                        <th style="width: 60px;padding: 0;">{{ $store.getters.translate('is_payed') }}</th>
                        <!--<th style="width: 100px;text-align: center;padding: 0;">{{ $store.getters.translate('certificate_required') }}</th>-->
                        <th style="width: 100px;text-align: center;padding: 0;">{{ $store.getters.translate('readspeaker') }}</th>
                        <th style="width: 75px;text-align: center;padding: 0;">{{ $store.getters.translate('read_aloud') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(candidate, index) in record.candidates" :key="index">
                        <td style="padding: 5px;">
                          <a @click="$router.push('/candidates/' + candidate.id)">{{ candidate.name }}</a>
                        </td>
                        <td style="padding: 5px;">
                          <v-text-field
                              flat
                              v-model="candidate.pivot.custom_order_number"
                              dense
                              class="centered-input"
                              placeholder="-"
                              single-line
                              @change="updateOrder(candidate.pivot)"/>
                        </td>
                        <td style="padding: 5px;">
                          <v-text-field
                              flat
                              v-model="candidate.pivot.order_number"
                              dense
                              class="centered-input"
                              placeholder="-"
                              single-line
                              @change="updateOrder(candidate.pivot)"/>
                        </td>
                        <td style="padding: 5px;">
                          <v-switch v-model="candidate.pivot.is_billed" @change="updateOrder(candidate.pivot)"/>
                        </td>
                        <td style="padding: 5px;">
                          <v-text-field
                              flat
                              v-model="candidate.pivot.invoice_number"
                              dense
                              class="centered-input"
                              placeholder="-"
                              single-line
                              @change="updateOrder(candidate.pivot)"/>
                        </td>
                        <td style="padding: 5px;">
                          <v-switch v-model="candidate.pivot.is_payed" @change="updateOrder(candidate.pivot)"/>
                        </td>
<!--                    <td style="padding: 5px;">
                          <v-switch v-model="candidate.pivot.certificate_required" @change="updateOrder(candidate.pivot)"/>
                        </td>-->
                        <td style="padding: 5px;">
                          <v-switch v-model="candidate.pivot.read_speaker" @change="updateOrder(candidate.pivot)"/>
                        </td>
                        <td style="padding: 5px;">
                          <v-switch v-model="candidate.pivot.read_aloud" @change="updateOrder(candidate.pivot)"/>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-simple-table v-if="record.scheme_owner.toLowerCase() === 'stipel' && !record.re_examination">
                  <template v-slot:default>
                    <thead>
                    <tr>
                      <th style="padding: 0;">{{ $store.getters.translate('candidate_name') }}</th>
                      <th style="text-align:center;padding: 0;">{{ $store.getters.translate('stipel') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(candidate, index) in record.candidates" :key="index">
                      <td style="padding: 5px;">
                        <a @click="$router.push('/candidates/' + candidate.id)">{{ candidate.name }}</a>
                      </td>
                      <td style="text-align:center;padding: 5px;">
                        <span v-if="candidate.stipelcandidate && candidate.stipelcandidate.stipel_attempts && candidate.stipelcandidate.stipel_attempts.length > 0">
                          {{ candidate.stipelcandidate.stipel_attempts[0].s_attempt_id }}
                        </span>
                        <v-btn v-else @click="registerStipel(candidate)" block small text>
                          {{ $store.getters.translate("register") }}
                        </v-btn>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <v-divider v-if="!$vuetify.breakpoint.mdAndDown" vertical/>
              <v-col :cols="!$vuetify.breakpoint.smAndDown ? 4 : 12">
                <base-row
                  :label="$store.getters.translate('exam_number')"
                  :value="record.id"/>
                <base-row
                  v-if="record.scheme"
                  :label="$store.getters.translate('scheme')"
                  :value="record.scheme.name"
                  :link="true"
                  @click="$router.push('/schemes/' + record.scheme_id)"/>
                <base-row
                  :label="$store.getters.translate('parent_exam_id')"
                  :value="record.re_exam_id"
                  :link="true"
                  @click="$router.push('/exams/' + record.re_exam.id)"/>
                <v-textarea
                    :class="'ma-0 pt-'+$store.getters.lineheight"
                    :label="$store.getters.translate('description')"
                    v-model="record.description"
                    validate-on-blur
                    :disabled="loading"/>
                <base-log-field :model_type="$models[page.name]" :model_id="record.id"/>
                <base-tags v-if="tags_required" v-model="record.tags" :module_name="page.name"/>
              </v-col>
            </v-row>
          </template>
          <template v-if="custom_fields.length > 0" v-slot:module_fields>
            <template v-for="field in custom_fields">
              <base-field
                  :key="field.name"
                  :value="record.custom_fields[field.name]"
                  :field="field"
                  @change="customFieldDataChanged"/>
            </template>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col>
        <v-card :loading="loading" :disabled="loading">
          <v-tabs
            v-model="tab"
            :centered="false"
            :grow="true"
            :vertical="!$vuetify.breakpoint.mobile"
            :right="false"
            hide-slider
            :icons-and-text="false"
            color="primary">
            <v-tab
              @click="active_session = session"
              class="pa-5"
              v-for="(session, key) in sessions"
              :key="key">
              {{ session.name }}
            </v-tab>
            <v-tab-item v-for="(session, key) in sessions" :key="key">
              <v-card flat style="padding: 25px">
                <v-card-title>
                  {{ session.name }}
                  <v-subheader>{{ $store.getters.translate(session.status) }}</v-subheader>
                  <v-spacer/>
                  <base-link
                    @change="linkOrCreate"
                    module_name="candidates"
                    :excluded_ids="$lodash.map(session.exam_candidate_sessions, 'candidate_id')"
                    @click="active_session = session"
                    :label="$store.getters.translate('add_candidates')"/>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        text
                        v-bind="attrs"
                        v-on="on"
                        small
                        @click="updateSession(session)"
                        color="primary">
                        <v-icon>mdi-content-save</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $store.getters.translate("save") }}</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <v-text-field
                      :class="'ma-0 pt-'+$store.getters.lineheight"
                      dense
                      filled
                      :label="$store.getters.translate('max_candidates')"
                      v-model="session.max_candidates"/>
                  <v-text-field
                      :class="'ma-0 pt-'+$store.getters.lineheight"
                      dense
                      filled
                      :label="$store.getters.translate('cesuur')"
                      v-model="session.cesuur"/>
                  <v-text-field
                      :class="'ma-0 pt-'+$store.getters.lineheight"
                      dense
                      filled
                      :label="$store.getters.translate('price')"
                      v-model="session.price"
                      type="number"/>
                  <v-select
                      :class="'ma-0 pt-'+$store.getters.lineheight"
                      dense
                      filled
                      :label="$store.getters.translate('room')"
                      v-model="session.room_id"
                      :items="$lodash.sortBy(rooms, 'name')"
                      item-text="name"
                      item-value="id"
                      clearable
                      :disabled="loading"
                      :loading="loading"/>
                  <v-select
                      :class="'ma-0 pt-'+$store.getters.lineheight"
                      dense
                      filled
                      :label="$store.getters.translate('examinators')"
                      v-model="session.examinators"
                      :items="$lodash.sortBy(examinators, 'name')"
                      item-text="name"
                      item-value="id"
                      multiple
                      return-object
                      clearable
                      append-outer-icon="mdi-folder-multiple-plus-outline"
                      @click:append-outer="addExaminatorsToAllSessions(session)"/>
                  <v-select
                      :class="'ma-0 pt-'+$store.getters.lineheight"
                      dense
                      filled
                      :label="$store.getters.translate('supervisors')"
                      v-model="session.supervisors"
                      :items="$lodash.sortBy(supervisors, 'name')"
                      item-text="name"
                      item-value="id"
                      multiple
                      return-object
                      clearable
                      append-outer-icon="mdi-folder-multiple-plus-outline"
                      @click:append-outer="addSupervisorsToAllSessions(session)"/>
                  <v-select
                      v-if="record.scheme_owner && (record.scheme_owner.toLowerCase() === 'relyon' || record.scheme_owner.toLowerCase() === 'scios' || record.scheme_owner.toLowerCase() === 'stipel')"
                      :class="'ma-0 pt-'+$store.getters.lineheight"
                      dense
                      filled
                      :label="$store.getters.translate('testvision_assessment')"
                      v-model="session.t_assessment_id"
                      :items="assessments"
                      item-text="displayName"
                      item-value="assessmentId"
                      clearable
                      :disabled="loading"
                      :loading="loading"/>
                </v-card-text>
                <v-data-table
                  :headers="participantsession_headers"
                  :items="session.exam_candidate_sessions"
                  selectable-key="id"
                  :show-select="false"
                  :key="exam_candidate_sessions_key"
                  multi-sort
                  :options.sync="options">
                  <template v-slot:item.id="{ item }">
                    <template>
                      <v-tooltip top v-if="item.status_check > 0">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" class="mr-3" x-small color="red">mdi-checkbox-blank-circle</v-icon>
                        </template>
                        <span>{{ $store.getters.translate("open_requests") }} {{ item.status_check }}</span>
                      </v-tooltip>
                      <v-tooltip v-else top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" class="mr-3" x-small color="green">mdi-checkbox-blank-circle</v-icon>
                        </template>
                        <span>{{ $store.getters.translate("no_open_requests") }}</span>
                      </v-tooltip>
                    </template>
                    <a @click="$router.push('/candidates/' + item.candidate_id)">
                      <v-avatar class="mr-1" size="20" v-if="item.person">
                        <v-img style="border: 1px" :src="item.person.avatar ? item.person.avatar : '../../../assets/images/no-user.jpg'"/>
                      </v-avatar>
                      {{ item.name }}
                    </a>
                  </template>
                  <template v-slot:item.result="{ item }">
                    <v-text-field
                      :disabled="item.status === 'absent' || item.status === 'exempt' || item.agreed_with_terms_on == null"
                      flat
                      v-model="item.result"
                      dense
                      class="centered-input"
                      style="width: 45px; border-radius: 3px"
                      placeholder="-"
                      single-line
                      @change="updateParticipant(session, item)"
                      :background-color="backgroundColor(session.cesuur, item)"
                      dark
                      :tabindex="item.name"/>
                  </template>
                  <template v-slot:item.status="{ item }">{{ $store.getters.translate(item.status) }}</template>
                  <template v-slot:item.updated_at="{ item }">{{ moment(item.updated_at).format("DD-MM-YYYY") }}</template>
                  <template v-slot:item.exam_version="{ item }">
                    <v-text-field
                      flat
                      v-model="item.exam_version"
                      dense
                      class="centered-input"
                      placeholder="-"
                      single-line
                      @change="updateParticipant(session, item)"/>
                  </template>
                  <template v-slot:item.from="{ item }">
                    <v-text-field
                      hide-details
                      dense
                      v-model="item.from"
                      v-mask="'##:##'"
                      placeholder="12:00"
                      clearable
                      @change="setTill(item, session)"
                      style="width: 70px"/>
                  </template>
                  <template v-slot:item.till="{ item }">
                    <v-text-field
                      hide-details
                      dense
                      v-model="item.till"
                      v-mask="'##:##'"
                      placeholder="14:30"
                      clearable
                      @change="updateParticipant(session, item)"
                      style="width: 70px"/>
                  </template>
                  <template v-slot:item.t_assessment_id="{ item }">
                    <template v-if="record.scheme_owner && (record.scheme_owner.toLowerCase() === 'relyon' || record.scheme_owner.toLowerCase() === 'scios' || record.scheme_owner.toLowerCase() === 'stipel')">
                      <v-btn v-if="!item.t_assessment_id" @click="createTestvisionAssignment(session, item)" block small text>
                        {{ $store.getters.translate("create") }}
                      </v-btn>
                      <span v-else>{{ item.t_assessment_id }}</span>
                    </template>
                    <template v-else>
                      <span>-</span>
                    </template>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="addToAllSessions(session, item)" color="secondary" v-bind="attrs" v-on="on" small text>
                          <v-icon small>mdi-folder-multiple-plus-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $store.getters.translate("add_to_all_sessions") }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="openDossier(item)" color="secondary" v-bind="attrs" v-on="on" small text>
                          <v-icon small>{{ $icons["dossiers"] }}</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $store.getters.translate("go_to_dossier") }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="item.status !== 'exempt' && item.status !== 'absent' && !item.result" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="setStatus(session.id, item, 'exempt')" color="secondary" v-bind="attrs" v-on="on" small text>
                          <v-icon small>mdi-bookmark-check</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $store.getters.translate("exempt") }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="item.status !== 'exempt' && item.status !== 'absent' && !item.result" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="setStatus(session.id, item, 'absent')" color="secondary" v-bind="attrs" v-on="on" small text>
                          <v-icon small>mdi-exit-to-app</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $store.getters.translate("absent") }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="item.status === 'exempt' || item.status === 'absent'" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="deleteStatus(session.id, item)" color="secondary" v-bind="attrs" v-on="on" small text>
                          <v-icon small>mdi-redo</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $store.getters.translate("reset") }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="deleteFromAllSessions(session.id, item)" color="secondary" v-bind="attrs" v-on="on" small text>
                          <v-icon small>mdi-delete-sweep</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $store.getters.translate("detach_from_all_sessions") }}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="deleteParticipant(session.id, item)" color="secondary" v-bind="attrs" v-on="on" small text>
                          <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $store.getters.translate("delete") }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
<!--    <v-row>
      <v-col>
        <base-planner-exam :loading="loading" :exam_name="record.name" :examination_date="record.examination_date" :sessions="sessions"/>
      </v-col>
    </v-row>-->
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="assessors_table" :page="page" :parent_module_id="record.id" relation_name="assessors" @edit="editAssessor" @change="load"/>
          <base-table-includable ref="notes_table" :page="page" :parent_module_id="record.id" relation_name="notes" @create="createNote" @edit="editNote" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <edit-note modal_name="edit_note" :module_name="page.name" :model_id="record.id" ref="edit_note" @document_preview="preview" @refresh="load" :model_type="$models[page.name]"
        :assessors="record.assessors" :candidates="record.candidates" :companies="record.companies" :dossiers="record.dossiers"/>
    <create-note
        modal_name="create_note"
        ref="create_note"
        :module_name="page.name"
        :assessors="record.assessors"
        :candidates="record.candidates"
        :companies="record.companies"
        :dossiers="record.dossiers"
        :model_type="$models[page.name]"
        :model_id="record.id"
        @refresh="load"/>
    <document-edit modal_name="document_edit" ref="document_edit"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import EditNote from "../notes/Edit";
import CreateNote from "../notes/Create";
import BaseLink from "../../components/commonComponents/BaseLink";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import moment from "moment";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import helpFunctions from "../../plugins/helpFunctions";
import DocumentEdit from "../documents/Edit";
import BaseLogField from "../../components/commonComponents/BaseLogField.vue";
//import BasePlannerExam from "../../components/commonComponents/BasePlannerExam";
//import Stepper from "./includables/Stepper";

export default {
  components: {
    BaseLogField,
    //BasePlannerExam,
    DocumentEdit,
    BaseTableIncludable,
    EditNote,
    CreateNote,
    BaseLink,
    BaseTabPanel,
    BaseBreadcrumb,
    //Stepper,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("exams"),
        name: "exams",
        model: "exam",
      },
      record: {
        name: "",
        exam_language: "nl",
        examination_date: null
      },
      languages: [
        { text: this.$store.getters.translate('nl'), value: 'nl' },
        { text: this.$store.getters.translate('en'), value: 'en' }
      ],
      loading: false,
      options: {},
      tab: 0,
      participantsession_headers: [],
      examinators: [],
      supervisors: [],
      locations: [],
      rooms: [],
      exams_for_count: [],
      candidates_count: 0,
      exams_message: '',
      old_room_id: null,
      old_examination_date: null,
      candidates: [],
      active_session: {},
      exam_candidate_sessions_key: 0,
      sessions: [],
      custom_fields_names: [],
      exclude_absent_candidates: true,
      exclude_failed_candidates: false,
      candidates_for_sending: {},
      dossiers_for_sending: [],
      selected_location: null,
      selected_room: null,
      usual_fields_required: [],
      custom_fields: [],
      tags_required: false,
      assessments: []
    };
  },
  created() {
    this.participantsession_headers = helpFunctions.participantsession_headers;
    this.getLocations();
    this.getExaminators();
    this.getSupervisors();
    this.getOptions();
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.$route.params.id)
        .then((response) => {
          this.loading = false;
          this.record = response.data;
          if (this.record.custom_fields == null || Array.isArray(this.record.custom_fields) || typeof this.record.custom_fields != "object") {
            this.record.custom_fields = {};
          }
          this.selected_location = this.record.location;
          this.selected_room = this.record.room;
          this.record.companies = [];
          this.old_room_id = this.record.room_id;
          this.old_examination_date = this.record.examination_date;
          response.data.candidates.forEach((candidate) => {
            candidate.companies.forEach((company) => {
              if (!this.record.companies.find((item) => item.id === company.id)) {
                this.record.companies.push({
                  id: company.id,
                  name: company.name,
                });
              }
            });
          });
          this.dossiers_for_sending = this.record.dossiers;
          this.loadSessions();
          this.updateIncludableTables();
          this.getRooms();
          if(this.record.scheme_owner && (this.record.scheme_owner.toLowerCase() === 'relyon' || this.record.scheme_owner.toLowerCase() === 'scios' || this.record.scheme_owner.toLowerCase() === 'stipel')) {
            this.getTestvisionAssessments();
          }
          //this.locations = helpFunctions.getLocations(this.record.scheme, 'exam');
          //this.rooms = helpFunctions.getRooms(this.record.scheme, this.record.location_id, 'exam');
          //this.examinators = helpFunctions.getAssessors(this.record.scheme, this.record.room_id, "examinator");
          //this.supervisors = helpFunctions.getAssessors(this.record.scheme, this.record.room_id, "supervisor");
          this.getFields();
          this.checkCapacity();
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    updateIncludableTables() {
      this.$refs.assessors_table.records = this.record.assessors;
      this.$refs.notes_table.records = this.record.notes;
      this.$refs.assessors_table.selected = [];
      this.$refs.notes_table.selected = [];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async loadSessions() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/exams/" + this.$route.params.id + "/sessions")
        .then((response) => {
          this.loading = false;
          this.sessions = this.$lodash.sortBy(response.data, "sort_order");
          this.candidates_for_sending = {};
          this.sessions.forEach((session) => {
            session.exam_candidate_sessions.forEach((exam_candidate_session) => {
              let candidate_id = exam_candidate_session.candidate_id;
              if(!this.candidates_for_sending[candidate_id]) {
                this.candidates_for_sending[candidate_id] = {}
                this.candidates_for_sending[candidate_id].name = exam_candidate_session.name;
                this.candidates_for_sending[candidate_id].failed = false;
                this.candidates_for_sending[candidate_id].absent = false;
              }
              if(exam_candidate_session.status === "failed") {
                this.candidates_for_sending[candidate_id].failed = true;
              }
              if(exam_candidate_session.status === "absent") {
                this.candidates_for_sending[candidate_id].absent = true;
              }
              this.record.dossiers.forEach((dossier) => {
                if(candidate_id === dossier.candidate_id) {
                  exam_candidate_session.agreed_with_terms_on = dossier.agreed_with_terms_on;
                }
              });
            });
          });
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    getLocations() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/locations")
          .then((response) => {
            this.loading = false;
            this.locations = response.data.data;
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
      /*this.record.location_id = null;
      this.record.room_id = null;
      this.examinators = [];
      this.supervisors = [];
      this.rooms = [];
      this.selected_examinator = null;
      this.selected_supervisor = null;
      this.selected_location = null;
      this.selected_room = null;
      this.locations = helpFunctions.getLocations(this.record.scheme, 'exam');*/
    },
    getRooms() {
      this.record.location_id = this.selected_location.id;
      if (this.record.location_id) {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/location-rooms", { id: this.record.location_id })
            .then((response) => {
              this.rooms = response.data;
              this.loading = false;
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
      } else {
        return [];
      }
      /*this.record.location_id = this.selected_location.id;
      this.record.room_id = null;
      this.examinators = [];
      this.supervisors = [];
      this.selected_examinator = null;
      this.selected_supervisor = null;
      this.selected_room = null;
      this.rooms = helpFunctions.getRooms(this.record.scheme, this.record.location_id, 'exam');*/
    },
    getAssessors() {
      this.record.room = this.selected_room;
      this.record.room_id = this.selected_room.id;
      //this.examinators = helpFunctions.getAssessors(this.record.scheme, this.record.room_id, "examinator");
      //this.supervisors = helpFunctions.getAssessors(this.record.scheme, this.record.room_id, "supervisor");
      this.checkCapacity();
    },
    async getExaminators() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/assessors?type=examinator")
          .then((response) => {
            this.examinators = response.data;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    async getSupervisors() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/assessors?type=supervisor")
          .then((response) => {
            this.supervisors = response.data;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    async getTestvisionAssessments() {
      this.$http
          .post(this.$store.getters.appUrl + "v2/testvision/get-assessments", {exam_id: this.record.id})
          .then((response) => {
            this.assessments = response.data;
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    checkCapacity() {
      if (this.record.room_id && this.record.examination_date && moment(this.record.examination_date, "DD-MM-YYYY", true).isValid()) {
        this.$http
            .post(this.$store.getters.appUrl + "v2/room-exams", { id: this.record.room_id, examination_date: this.record.examination_date })
            .then((response) => {
              this.exams_for_count = response.data;
              this.candidates_count = 0;
              this.exams_for_count.forEach((exam) => {
                this.candidates_count += exam.candidates.length;
              });
              this.exams_message = this.exams_for_count.length + ' exams with ' + this.candidates_count + ' candidates planned in ' + this.selected_room.name + ' on ' + this.record.examination_date;
            })
            .catch((error) => {
              this.$toasted.error(error);
            });
      }
    },
    save() {
      if(!this.loading) {
        let error = false;
        for (let key in this.usual_fields_required) {
          if (key !== 'examinators' && key !== 'supervisors' && this.usual_fields_required[key] && (!this.record[key] || this.record[key] === '')) {
            this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(key));
            error = true;
          }
        }
        if (!error) {
          if((this.record.examination_date !== this.old_examination_date || this.record.room_id !== this.old_room_id) &&
              this.selected_room.maximum_capacity && this.selected_room.maximum_capacity < (this.record.candidates.length + this.candidates_count)) {
            this.$toasted.error(this.$store.getters.translate("room_too_small"));
          }
          else {
            this.loading = true;
            this.$http
                .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id, this.record)
                .then((response) => {
                  this.loading = false;
                  if (response.status == 200) {
                    this.$toasted.success(this.$store.getters.translate("success"));
                    this.load();
                  } else {
                    var errorMessage = response.data.message;
                    var errors = Object.keys(response.data.message);
                    this.$toasted.error([errorMessage[errors[0]]]);
                  }
                })
                .catch((error) => {
                  this.$toasted.error(error);
                  this.loading = false;
                });
          }
        }
      }
    },
    archive() {
      this.$confirm(this.$store.getters.translate("confirm")).then((res) => {
        if (res) {
          this.$http
            .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + this.record.id)
            .then(() => {
              this.$router.push("/" + this.page.name);
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
        }
      });
    },
    deleteParticipant(session_id, item) {
      this.$confirm(this.$store.getters.translate("confirm")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
            .delete(this.$store.getters.appUrl + "v2/examcandidatesessions/" + item.id)
            .then(() => {
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
              this.loadSessions();
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
        }
      });
    },
    deleteFromAllSessions(session_id, item) {
      this.$confirm(this.$store.getters.translate("confirm")).then((res) => {
        if (res) {
          this.loading = true;
          this.$http
            .delete(this.$store.getters.appUrl + "v2/examcandidatesessions/" + item.id + "?detach_from_all=true")
            .then(() => {
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
              this.loadSessions();
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
        }
      });
    },
    setStatus(session_id, item, status) {
      this.$confirm(this.$store.getters.translate("confirm")).then((res) => {
        if (res) {
          if(status === 'absent') {
            this.sessions.forEach((session) => {
              session.exam_candidate_sessions.forEach((exam_candidate_session) => {
                if(item.candidate_id === exam_candidate_session.candidate_id && !exam_candidate_session.result && exam_candidate_session.status !== 'exempt') {
                  this.loading = true;
                  exam_candidate_session.new_status = {
                    name: status
                  };
                  exam_candidate_session.result = null;
                  this.$http
                      .patch(this.$store.getters.appUrl + "v2/examcandidatesessions/" + exam_candidate_session.id, exam_candidate_session)
                      .then((response) => {
                        exam_candidate_session.status = response.data.status;
                        exam_candidate_session.new_status = null;
                        this.loading = false;
                      })
                      .catch((error) => {
                        this.$toasted.error(error);
                        this.loading = false;
                      });
                }
              });
            });
          }
          else {
            this.loading = true;
            item.new_status = {
              name: status
            };
            item.result = null;
            this.$http
                .patch(this.$store.getters.appUrl + "v2/examcandidatesessions/" + item.id, item)
                .then((response) => {
                  item.status = response.data.status;
                  item.new_status = null;
                  this.loading = false;
                })
                .catch((error) => {
                  this.$toasted.error(error);
                  this.loading = false;
                });
          }
        }
      });
    },
    deleteStatus(session_id, item) {
      this.$confirm(this.$store.getters.translate("confirm")).then((res) => {
        if (res) {
          this.loading = true;
          item.remove_status = true;
          this.$http
            .patch(this.$store.getters.appUrl + "v2/examcandidatesessions/" + item.id, item)
            .then((response) => {
              item.status = response.data.status;
              item.remove_status = null;
              this.loading = false;
            })
            .catch((error) => {
              this.$toasted.error(error);
              this.loading = false;
            });
        }
      });
    },
    addToAllSessions(session, item) {
      item.attach_to_all = true;
      this.loading = true;
      this.$http
        .patch(this.$store.getters.appUrl + "v2/examcandidatesessions/" + item.id, item)
        .then((response) => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("success"));
          item.status = response.data.status;
          this.loadSessions();
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    updateParticipant(session, item) {
      item.session_id = session.id;
      item.scheme_session_id = session.scheme_session.id;
      this.loading = true;
      this.$http
        .patch(this.$store.getters.appUrl + "v2/examcandidatesessions/" + item.id, item)
        .then((response) => {
          item.status = response.data.status;
          this.loading = false;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    updateSession(session) {
      this.loading = true;
      this.$http
        .put(this.$store.getters.appUrl + "v2/sessions/" + session.id, session)
        .then((response) => {
          this.loading = false;
          this.load();
          if (response.status == 200) {
            this.$toasted.success(this.$store.getters.translate("success"));
          } else {
            var errorMessage = response.data.message;
            var errors = Object.keys(response.data.message);
            this.$toasted.error([errorMessage[errors[0]]]);
          }
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    updateOrder(pivot) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/update-exam-order", pivot)
          .then(() => {
            this.$toasted.success(this.$store.getters.translate("success"));
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    registerStipel(candidate) {
      if(!this.loading) {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/stipel/certify", {candidate_id: candidate.id, exam_id: this.record.id})
            .then((response) => {
              candidate.stipelcandidate = response.data;
              this.$toasted.success(this.$store.getters.translate("success"));
              this.loading = false;
            })
            .catch((error) => {
              this.$toasted.error(error.response.data.message);
              this.loading = false;
            });
      }
    },
    createTestvisionAssignment(session, item) {
      if(!session.t_assessment_id) {
        this.$toasted.error(this.$store.getters.translate("assessment_not_selected"));
      }
      else {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/testvision/create-assignment", {
              candidate_id: item.candidate_id,
              exam_id: this.record.id,
              assessment_id: session.t_assessment_id,
              exam_candidate_session_id: item.id
            })
            .then((response) => {
              this.loading = false;
              this.load();
              if (response.status == 200) {
                this.$toasted.success(this.$store.getters.translate("success"));
              } else {
                var errorMessage = response.data.message;
                var errors = Object.keys(response.data.message);
                this.$toasted.error([errorMessage[errors[0]]]);
              }
            })
            .catch((error) => {
              this.$toasted.error(error.response.data.message);
              this.loading = false;
            });
      }
    },
    backgroundColor(cesuur, item) {
      if (item.status === "exempt" || item.status === "absent") {
        return "grey";
      }
      if (parseInt(cesuur) > parseInt(item.result) || !item.result) {
        return "#ff7f75";
      }
      return "#56d65b";
    },
    editAssessor(item) {
      this.$router.push("/assessors/" + item.id);
    },
    preview(file) {
      this.$refs.document_edit.openModal('document_edit');
      this.$refs.document_edit.load(file);
    },
    editNote(item) {
      this.$refs.edit_note.openModal('edit_note');
      this.$refs.edit_note.load(item.id);
    },
    createNote() {
      this.$refs.create_note.openModal('create_note');
    },
    setTill(item, session) {
      if(!item.from) {
        item.till = null;
      }
      else if(session.scheme_session && session.scheme_session.duration) {
        let item_hours = item.from.substring(0, 2);
        let item_minutes = item.from.substring(3, 5);
        let duration_hours = session.scheme_session.duration.substring(0, 2);
        let duration_minutes = session.scheme_session.duration.substring(3, 5);
        item.till = moment({hour: item_hours, minute: item_minutes})
            .add({hour: duration_hours, minute: duration_minutes})
            .format("HH:mm");
      }
      this.updateParticipant(session, item);
    },
    addExaminatorsToAllSessions(session) {
      session.add_examinators_to_all_sessions = true;
      this.updateSession(session);
      this.$toasted.success(this.$store.translate("adding_examinators_to_all_sessions"));
      this.loadSessions();
    },
    addSupervisorsToAllSessions(session) {
      session.add_supervisors_to_all_sessions = true;
      this.updateSession(session);
      this.$toasted.success(this.$store.translate("adding_supervisors_to_all_sessions"));
      this.loadSessions();
    },
    linkOrCreate(objects) {
      let error = false;
      if(this.selected_room.maximum_capacity && this.selected_room.maximum_capacity < (objects.length + this.candidates_count)) {
        this.$toasted.error(this.$store.getters.translate("room_too_small"));
        error = true;
      }
      else {
        this.sessions.forEach((session) => {
          if (session.max_candidates && session.max_candidates < (objects.length + session.exam_candidate_sessions.length)) {
            this.$toasted.error(session.name + ": " + this.$store.getters.translate("session_too_small"));
            error = true;
          }
        });
      }
      if(!error) {
        this.loading = true;
        this.$http
          .post(this.$store.getters.appUrl + "v2/sessions/" + this.active_session.id + "/examcandidatesessions", { candidates: objects })
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("success"));
            this.load();
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
      }
    },
    saveOptions() {
      localStorage.setItem("exam_candidate_sessions_options", JSON.stringify(this.options));
    },
    getOptions() {
      if (localStorage.getItem("exam_candidate_sessions_options")) {
        this.options = JSON.parse(localStorage.getItem("exam_candidate_sessions_options"));
      }
    },
    openDossier(item) {
      if (item.dossier_id) {
        this.$router.push("/dossiers/" + item.dossier_id);
      } else {
        item.exam_candidate_session_id = item.id;
        this.$http
          .post(this.$store.getters.appUrl + "v2/dossiers", item)
          .then((response) => {
            this.$router.push("/dossiers/" + response.data);
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
      }
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_on_update=true&model_type=" + this.page.model)
          .then((response) => {
            this.custom_fields = [];
            this.usual_fields_required = [];
            response.data.data.forEach((field) => {
              if (field.custom_field == 1) {
                this.custom_fields.push(field);
              }
              else if(field.name === 'tags') {
                this.tags_required = true;
              }
              else if(field.name === 'location_name') {
                this.usual_fields_required['location_id'] = field.mandatory;
              }
              else if(field.name === 'room_name') {
                this.usual_fields_required['room_id'] = field.mandatory;
              }
              else if(field.name === 'scheme_name') {
                this.usual_fields_required['scheme_id'] = field.mandatory;
              }
              else if(field.field_type !== 'boolean') {
                this.usual_fields_required[field.name] = field.mandatory;
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
  watch: {
    options: {
      handler() {
        this.saveOptions();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
.centered-input {
  position: relative;
  top: 7px;
}
</style>